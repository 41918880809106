import React, {useEffect, useState} from 'react'
import {config, getConversationMessages, getUsers} from "../../graphql-api-config";
import Amplify from '@aws-amplify/core'
import {
    PAYLOAD_TYPE_TEXT,
    PAYLOAD_TYPE_VIDEO_CHAT_END,
    PAYLOAD_TYPE_VIDEO_CHAT_START
} from "../../consts/message-consts";
import VideoChatDialog from "../video-chat";
import VideoChat from "../video-chat";

Amplify.configure(config);

const mockMessages = [
    {
        messageId: "m1",
        senderUserId: "Tharanga",
        receiverUserId: "Ruwan",
        payload: {
            type: "TEXT",
            content: "Hello"
        }
    },
    {
        messageId: "m2",
        senderUserId: "Ruwan",
        receiverUserId: "Tharanga",
        payload: {
            type: "TEXT",
            content: "Hello z11"
        }
    }
]

function MessageRow({message, authUser}) {

    let rowDirectionClass = "";
    let rowColorClass = "bg-blue-50";

    if (message.senderUserId === authUser.userId) {
        rowDirectionClass = "flex-row-reverse"
        rowColorClass = "bg-green-50"
    }

    let messageText = message.payload.content

    return (<div className={`flex flex-row border-1 border-gray-50 rounded p-2 ${rowDirectionClass}`}>
        <div className={`p-2 w-3/4 ${rowColorClass}`}><pre>{messageText}</pre></div>
    </div>)

}

export default function Messenger({accessKey, authUser, newMessage}) {
    const [messages, setMessages] = useState([]);
    useEffect(  () => {
        if (newMessage) {
            const newMessages = Object.assign([], messages);
            newMessage.forEach(message => {
                newMessages.push(message);
            })
            setMessages(newMessages);
        }

    }, [newMessage]);

    return (
        <div>
            <div className="flex flex-col">
                {messages.map((message)=>
                    <MessageRow message={message} authUser={authUser} />
                )}
            </div>
        </div>
    )
}