import React, {useEffect, useState} from 'react'
import {config, getConversationMessages, getUsers} from "../../graphql-api-config";
import Amplify from '@aws-amplify/core'
import {
    PAYLOAD_TYPE_TEXT,
    PAYLOAD_TYPE_VIDEO_CHAT_END,
    PAYLOAD_TYPE_VIDEO_CHAT_START
} from "../../consts/message-consts";
import VideoChatDialog from "../video-chat";
import VideoChat from "../video-chat";

Amplify.configure(config);

const mockMessages = [
    {
        messageId: "m1",
        senderUserId: "Tharanga",
        receiverUserId: "Ruwan",
        payload: {
            type: "TEXT",
            content: "Hello"
        }
    },
    {
        messageId: "m2",
        senderUserId: "Ruwan",
        receiverUserId: "Tharanga",
        payload: {
            type: "TEXT",
            content: "Hello z11"
        }
    }
]

function MessageRow({message, authUser}) {

    let rowDirectionClass = "";
    let rowColorClass = "bg-blue-50";

    if (message.senderUserId === authUser.userId) {
        rowDirectionClass = "flex-row-reverse"
        rowColorClass = "bg-green-50"
    }

    let messageText = message.payload.content

    return (<div className={`flex flex-row h-16 border-1 border-gray-50 rounded p-2 ${rowDirectionClass}`}>
        <div className={`p-2 w-3/4 ${rowColorClass}`}>{messageText}</div>
    </div>)

}

export default function Messenger({accessKey, authUser, selectedUser, newMessage, receivedMessage}) {
    const [conversation, setConversation] = useState({});
    const [messages, setMessages] = useState([]);
    const [participantId, setParticipantId] = useState();
    const [videoChatInfo, setVideoChatIfo] = useState({meeting: null, attendee: null});

    useEffect(  () => {
        if (selectedUser) {
            console.log("selectedUser", selectedUser)
            setParticipantId(selectedUser.userId)
        }

    }, [selectedUser]);

    const appendMessage = (message) => {
        const newMessages = Object.assign([], messages);
        newMessages.push(message);
        setMessages(newMessages);
    };

    async function fetchMessages() {
        try {
            setMessages([])
            const { conversationMessages } = (await getConversationMessages(accessKey, participantId)).data
            console.log()
            const { conversation, messages } = conversationMessages;
            setConversation(conversation);
            setMessages(messages.map(m=>{
                m.payload = JSON.parse(m.payload);
                return m;
            }));
        } catch (e) {
            console.error("getConversationMessages failed", e);
        }
    }

    useEffect(  () => {
        if (participantId) {
            fetchMessages()
        }

    }, [participantId]);

    const processVideoChatInfo = (message)=> {
        if (message.payload.type === PAYLOAD_TYPE_VIDEO_CHAT_START) {
            const { meeting, attendees } = message.payload.metadata;
            setVideoChatIfo({
                meeting,
                attendee: attendees[authUser.userId]
            });
        } else if (message.payload.type === PAYLOAD_TYPE_VIDEO_CHAT_END) {
            // TODO !!!
            setVideoChatIfo(null);
        }else {
            setVideoChatIfo(null);
        }
    }

    useEffect(  () => {
        if (newMessage) {
            processVideoChatInfo(newMessage)
            appendMessage(newMessage)
        }

    }, [newMessage]);

    useEffect(  () => {
        if (
            receivedMessage
            && selectedUser
            && (receivedMessage.participantId === selectedUser.userId)) {
            processVideoChatInfo(receivedMessage)
            appendMessage(receivedMessage)
        }
    }, [receivedMessage]);

    const meetingEndHandler = () => {
        setVideoChatIfo(null);
    }

    return (
        <div>
            {(videoChatInfo && videoChatInfo.meeting)? <VideoChat
                    accessKey={accessKey}
                    videoChatInfo={videoChatInfo}
                    selectedUser={selectedUser}
                    meetingEndHandler={meetingEndHandler}
                />:
                <div className="flex flex-col">
                    {messages.map((message)=>
                        <MessageRow message={message} authUser={authUser} />
                    )}
                </div>
            }
        </div>
    )
}