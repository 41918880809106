/* eslint-disable */
export default {
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_UsFC3M3xV',
        userPoolWebClientId: '5kiq0ivofm1dkuitdmnjeiaohs',
        oauth: {
            domain: "accounts.happrdev.click",
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
            redirectSignIn: "https://chatbot-demo.happrdev.click",
            redirectSignOut: "https://chatbot-demo.happrdev.click",
            responseType: "code"
        }
    },
};
