import React, {useState, useEffect} from 'react'
import UsersList from "../users-list";
import Messenger from "../messenger";
import MessagePublish from "../message-publish";
import Amplify from '@aws-amplify/core'
import {config, subscribeMessagesByUserId} from "../../graphql-api-config";

Amplify.configure(config);

export default function ChatDemo({authUser, accessKey}) {
    const [selectedUser, setSelectedUser] = useState();
    const [newMessage, setNewMessage] = useState();
    const [receivedMessage, setReceivedMessage] = useState();

    const onAddMessage = message => {
        setNewMessage(message);
    }

    useEffect(() => {
        let subscription;

        if (authUser) {
            subscription = subscribeMessagesByUserId(accessKey, authUser.userId, request => {
                request.payload = JSON.parse(request.payload);
                setReceivedMessage(request)
            })
            console.log("subscription......")
        }

        return () => {
            if (subscription) {
                console.log("unsubscribe......")
                subscription.unsubscribe()
            }
        }
    }, [authUser])

    return (
        <div className="flex flex-col min-h-screen">

            <nav className="flex items-center justify-between bg-gray-900 text-white px-4 py-3">
                <a href="#" className="text-2xl font-bold">Chat Demo</a>
                <button className="text-white hover:text-gray-300 focus:outline-none focus:text-gray-300">
                    {authUser && <span>{authUser.name}</span>}
                </button>
            </nav>


            <div className="flex-grow flex">

                <div className="w-1/2 p-4">
                    <div>Users List</div>
                    <div className="max-height-wrapper overflow-y-auto">
                        <UsersList
                            authUser={authUser}
                            accessKey={accessKey}
                            onSelectUser={user=>{
                                setSelectedUser(user)
                                setReceivedMessage()
                            }}
                            receivedMessage={receivedMessage}
                        />
                    </div>
                </div>

                <div className="w-1/2 p-4">

                    <div>Messenger | {selectedUser && <span className="text-blue-500 font-bold">Chat with {selectedUser.name}</span>}</div>
                    <div className="max-height-wrapper overflow-y-auto">
                        <Messenger
                            authUser={authUser}
                            accessKey={accessKey}
                            selectedUser={selectedUser}
                            newMessage={newMessage}
                            receivedMessage={receivedMessage}
                        />
                    </div>
                </div>
            </div>

            <MessagePublish accessKey={accessKey} authUser={authUser} selectedUser={selectedUser} onAddMessage={onAddMessage}/>

        </div>
    )
}