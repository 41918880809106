import { ThemeProvider } from 'styled-components';
import {
    AudioInputControl,
    AudioOutputControl,
    ControlBar,
    ControlBarButton,
    Phone,
    useMeetingManager,
    MeetingStatus,
    useMeetingStatus,
    VideoInputControl,
    VideoTileGrid,

    Flex,
    FormField,
    Input,
    PrimaryButton, lightTheme, MeetingProvider,
} from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import React, {useState, useEffect, Fragment} from 'react'
import {
    config, publishMessage,
} from "../../graphql-api-config";
import Amplify from '@aws-amplify/core'
import {PAYLOAD_TYPE_VIDEO_CHAT_END} from "../../consts/message-consts";

Amplify.configure(config);

function Meeting({accessKey, selectedUser, meetingEndHandler}) {
    const meetingManager = useMeetingManager();
    const meetingStatus = useMeetingStatus();

    const clickedEndMeeting = async () => {
        const meetingId = meetingManager.meetingId;
        if (meetingId) {
            await meetingManager.leave();
            await publishMessage(accessKey, selectedUser.userId, JSON.stringify({ type: PAYLOAD_TYPE_VIDEO_CHAT_END, content: meetingId }))
            meetingEndHandler();
        }
    }

    return <div style={{marginTop: '2rem', height: '40rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <VideoTileGrid/>
        {meetingStatus === MeetingStatus.Succeeded ?
            <ControlBar
                layout="undocked-horizontal"
                showLabels
            >
                <AudioInputControl />
                <VideoInputControl />
                <AudioOutputControl />
                <ControlBarButton icon={<Phone />} onClick={clickedEndMeeting} label="End" />
            </ControlBar>
            :
            <div/>
        }
    </div>
}

function MeetingForm({meeting, attendee}) {
    const meetingManager = useMeetingManager();

    async function joinMeeting() {
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
            meeting,
            attendee
        );
        await meetingManager.join(meetingSessionConfiguration);
        await meetingManager.start();
    }

    useEffect(  () => {
        if (meeting) {
            joinMeeting();
        }
    }, [meeting]);


    return (<div className=""></div>)

}

export default function VideoChat({accessKey, videoChatInfo, meetingEndHandler, selectedUser}) {
    const { meeting, attendee } = videoChatInfo;
    return (
        <ThemeProvider theme={lightTheme}>
            <MeetingProvider>
                <MeetingForm accessKey={accessKey} meeting={meeting} attendee={attendee} />
                <Meeting accessKey={accessKey} selectedUser={selectedUser} meetingEndHandler={meetingEndHandler}/>
            </MeetingProvider>
        </ThemeProvider>
    );
}