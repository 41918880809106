import React, {useEffect, useState} from 'react'
import {config, getUsers} from "../../graphql-api-config";
import Amplify from '@aws-amplify/core'

Amplify.configure(config);

export default function UsersList({accessKey, onSelectUser, receivedMessage}) {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    console.log("receivedMessage", receivedMessage)
    async function fetchUsers() {
        try {
            const { data } = await getUsers(accessKey)
            setUsers(data.users);
        } catch (e) {
            console.error("fetchUsers failed", e);
        }
    }

    useEffect(  () => {
        if (accessKey) {
            fetchUsers()
        }

    }, [accessKey]);

    useEffect(  () => {
        if (selectedUser) {
            onSelectUser(selectedUser)
        }
    }, [selectedUser]);

    return (
        <>
            <ul role="list" className="divide-y divide-gray-200">
                {users.map((user) => (
                    <li key={user.email}
                        className={`flex py-4 cursor-pointer hover:border-indigo-100 hover:border-2 ${(receivedMessage && (receivedMessage.participantId === user.userId))?"bg-red-100":""}
                         ${(selectedUser && selectedUser.userId === user.userId? "bg-indigo-100":"")}`}
                        onClick={()=>{setSelectedUser(user)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10 rounded-full">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>

                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">{user.name}</p>
                            <p className="text-sm text-gray-500">{user.email}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}