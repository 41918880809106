import './App.css';
import ChatDemo from "./pages/chat-demo";
import Sign from "./pages/signin";
import React, {useState, useEffect} from 'react'
import {getProfile} from "./graphql-api-config";
import VideoChat from "./pages/video-chat";
import ChatBotDemo from "./pages/chatbot-demo";
import { Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import {LockClosedIcon} from "@heroicons/react/solid";

Auth.configure(awsExports);

function App() {
    const [accessKey, setAccessKey] = useState();
    const [authUser, setAuthUser] = useState();
    const onSuccessHandle = ak => {
        setAccessKey(ak);
    }

    async function fetchUserProfile() {
        try {
            const { data } = await getProfile(accessKey);
            setAuthUser(data.profile.user);
        } catch (e) {
            console.error("fetchUserProfile failed", e);
        }

    }

    useEffect(() => {
        if (accessKey) {
            fetchUserProfile()
        }
    }, [accessKey]);

    const handleLogin = async () => {
        await Auth.federatedSignIn()
    }

    const handleLogout = async () => {
        try {
            await Auth.signOut();
        } catch (e) {

        }
        window.location = '/';
    }

    const handleAuthenticate = async ()=>{
        try {
            const token = await (await Auth.currentSession()).getAccessToken().getJwtToken();
            if (token) {
                console.log("session", token)
                setAccessKey(token);
            }
        } catch (e) {
        }
    }

    useEffect(() => {
        handleAuthenticate();
    }, []);

    return (
        <div>
            {authUser ? <ChatBotDemo authUser={authUser} accessKey={accessKey}/> :
                <div className="w-full flex flex-row justify-center items-center pt-2">
                    <div className="w-40">
                        <button
                            type="button"
                            onClick={handleLogin}
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true"/>
                </span>
                            Sign in
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}

export default App;
