import React, {useState, useEffect} from 'react'

import Messenger from "../chatbot";
import MessagePublish from "../chatbot-message-publish";

export default function ChatBotDemo({authUser, accessKey}) {
    const [newMessage, setNewMessage] = useState();

    const onAddMessage = message => {
        setNewMessage(message);
    }

    return (
        <div className="flex flex-col min-h-screen">

            <nav className="flex items-center justify-between bg-gray-900 text-white px-4 py-3">
                <a href="#" className="text-2xl font-bold">ChatBot Demo</a>
                <button className="text-white hover:text-gray-300 focus:outline-none focus:text-gray-300">
                    {authUser && <span>{authUser.name}</span>}
                </button>
            </nav>


            <div className="flex-grow flex">

                <div className="w-full p-4">

                    <div>Messenger</div>
                    <div className="max-height-wrapper overflow-y-auto">
                        <Messenger
                            authUser={authUser}
                            accessKey={accessKey}
                            newMessage={newMessage}
                        />
                    </div>
                </div>
            </div>

            <MessagePublish
                accessKey={accessKey}
                authUser={authUser}
                onAddMessage={onAddMessage}
            />

        </div>
    )
}