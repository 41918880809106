import API, { graphqlOperation } from '@aws-amplify/api'

export const config = {
    "aws_appsync_graphqlEndpoint": "https://graphql.happrdev.click/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "null",
}

const publishMessageMutationDoc = /* GraphQL */ `
    mutation Mutation($participantId: String!, $payload: AWSJSON!) {
      publishMessage(participantId:$participantId, payload: $payload) {
        conversationId
        messageId
        participantId
        payload
        receiverUserId
        senderUserId
        sentAt
        userId
      }
    }
`

const endVideoChatMutationDoc = /* GraphQL */ `
    mutation Mutation($meetingId: String!) {
      endVideoChat(meetingId: $meetingId) {
        status
        meetingId
      }
    }
`

const publishChatBotMessageMutationDoc = /* GraphQL */ `
    mutation Mutation($text: String!) {
      chatBotPostText(text:$text) {
        messages {
          content
          contentType
        }
      }
    }
`

const conversationMessagesQueryDoc = /* GraphQL */ `
    query Query($participantId: String!) {
      conversationMessages(participantId: $participantId) {
        conversation {
          conversationId
          participantId
          userId
        }
        messages {
          conversationId
          messageId
          payload
          receiverUserId
          senderUserId
          sentAt
        }
      }
    }
`

const usersDoc = /* GraphQL */ `
    query Query {
      users {
        email
        name
        userId
      }
    }
`

const profileDoc = /* GraphQL */ `
    query Query {
      profile {
        user {
          dob
          first_name
          last_name
          phone
          profile_picture_url
          qr_code_url
          user_id
        }
        subscriptions {
          end_date
          start_date
          status
          subscription_id
          subscription_name
          user_id
        }
        agent_settings {
          agent_name
          user_id
        }
      }
    }
`

export const subscribeMessagesByUserIdDoc = /* GraphQL */ `
    subscription Subscribe($userId: String!) {
      subscribeMessagesByUserId(userId: $userId) {
        conversationId
        messageId
        participantId
        payload
        receiverUserId
        senderUserId
        sentAt
        userId
      }
    }
`

export const subscribeMessagesByConversationIdDoc = /* GraphQL */ `
    subscription Subscribe($conversationId: String!) {
      subscribeMessagesByConversationId(conversationId: $conversationId) {
        conversationId
        messageId
        participantId
        payload
        receiverUserId
        senderUserId
        sentAt
        userId
      }
    }
`

export const subscribeEndVideoChatDoc = /* GraphQL */ `
    subscription Subscribe($meetingId: String!) {
      subscribeEndVideoChat(meetingId: $meetingId) {
        status
        meetingId
      }
    }
`

export async function publishMessage(authToken, participantId, payload) {
    return await API.graphql(graphqlOperation(publishMessageMutationDoc, { participantId, payload }, authToken))
}

export async function publishChatBotMessage(authToken, text) {
    return await API.graphql(graphqlOperation(publishChatBotMessageMutationDoc, { text }, authToken))
}

export async function endVideoChat(authToken, meetingId) {
    return await API.graphql(graphqlOperation(endVideoChatMutationDoc, { meetingId }, authToken))
}

export async function getConversationMessages(authToken, participantId) {
    return await API.graphql(graphqlOperation(conversationMessagesQueryDoc, { participantId }, authToken))
}

export async function getUsers(authToken) {
    return await API.graphql(graphqlOperation(usersDoc, { }, authToken))
}

export async function getProfile(authToken) {
    return await API.graphql(graphqlOperation(profileDoc, { }, authToken))
}

export function subscribeMessagesByUserId(authToken, userId, next, error) {
    return API.graphql(graphqlOperation(subscribeMessagesByUserIdDoc, { userId }, authToken)).subscribe({
    next: ({ provider, value }) => {
        console.log("value.data", value)
        next(value.data.subscribeMessagesByUserId, provider, value)
    },
    error: error || console.log,
    })
}

export function subscribeMessagesByConversationId(authToken, userId, next, error) {
    return API.graphql(graphqlOperation(subscribeMessagesByConversationIdDoc, { userId }, authToken)).subscribe({
        next: ({ provider, value }) => {
            console.log("value.data", value)
            next(value.data.subscribeMessagesByConversationId, provider, value)
        },
        error: error || console.log,
    })
}

export function subscribeEndVideoChat(authToken, meetingId, next, error) {
    return API.graphql(graphqlOperation(subscribeEndVideoChatDoc, { meetingId }, authToken)).subscribe({
        next: ({ provider, value }) => {
            console.log("value.data", value)
            next(value.data.subscribeEndVideoChat, provider, value)
        },
        error: error || console.log,
    })
}