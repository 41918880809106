import React, {useEffect, useState} from 'react'
import {publishChatBotMessage, publishMessage} from "../../graphql-api-config";
import {PAYLOAD_TYPE_TEXT, PAYLOAD_TYPE_VIDEO_CHAT_START} from "../../consts/message-consts";

export default function ChatbotMessagePublish({authUser, accessKey, onAddMessage}) {
    const [messageText, setMessageText] = useState();

    const handleChangeMessageText = event => {
        setMessageText(event.target.value);
    }

    const _publishMessage = async () => {
        try {
            updateFormState(true)
            const resp = await publishChatBotMessage(accessKey, messageText)
            console.log("publishMessage", resp)

            updateFormState(false)

            return resp;
        } catch (err) {
            console.error("publish message failed", err);
            return false;
        }
    }

    const onTextMessageSubmit = async event => {
        event.preventDefault();
        const newMessage = { type: PAYLOAD_TYPE_TEXT, content: messageText };
        const resp = await _publishMessage();
        setMessageText("");

        const messages = [];

        const userMessage = { payload: newMessage, senderUserId: authUser.userId };
        messages.push(userMessage);

        if (resp
            && resp.data
            && resp.data.chatBotPostText
            && resp.data.chatBotPostText.messages
            && Array.isArray(resp.data.chatBotPostText.messages)
            && resp.data.chatBotPostText.messages.length > 0
        ) {
            // const botPayload = { type: PAYLOAD_TYPE_TEXT, content: resp.data.messages[0].content };
            // const botMessage = { payload: botPayload, senderUserId: 'bot' }
            // onAddMessage({
            //     userMessage,
            //     botMessage
            // })

            resp.data.chatBotPostText.messages.forEach(message => {
                const botMessage = { payload: message, senderUserId: 'bot' }
                messages.push(botMessage)
            });
        } else {
            console.error("FAILED")
            const botPayload = { type: PAYLOAD_TYPE_TEXT, content: 'Sorry. Please repeat your question' };
            const botMessage = { payload: botPayload, senderUserId: 'bot' }
            messages.push(botMessage)
        }

        onAddMessage(messages);
    }

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, { processing, success, failed, message }))
    }

    return (
        <div className="bg-gray-100 text-white px-4 py-3 flex flex-row w-full">

            <form className="flex items-center border-b border-b-2 border-blue-500 py-2 w-full gap-4" onSubmit={onTextMessageSubmit}>
                <input
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    value={messageText}
                    type="text" placeholder="Type your message..." onChange={handleChangeMessageText}/>

                <button
                    className="flex-shrink-0 bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded"
                    type="submit">
                    {formState.processing?<span>...</span>:<span>Send</span>}
                </button>


            </form>
        </div>
    )
}